pwa-camera-modal-instance {
  z-index: 1500;
}
pwa-camera-modal-instance > div > div.camera-video > video,
pwa-camera-modal-instance video {
  transform: scaleX(1) !important;
}

#host::shadow video {
  transform: scaleX(1) !important;
}
