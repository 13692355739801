.checkbox {
  margin-top: 16px;
  margin-bottom: 12px;
}

.checkbox-label {
  font-size: 1rem;
  align-items: center;
  gap: 6px;
  grid-gap: 6px;
  flex-wrap: wrap;
}

.terms-and-policy-link {
  font-size: 1rem;
  font-weight: 500;
  border: none;
  text-decoration: underline;
  color: var(--amplify-colors-brand-primary-80);
  padding: 2px;
}
.terms-and-policy-link:focus {
  text-decoration: none;
}

.privacy-stripe {
  width: 100%;
  background-color: var(--amplify-colors-brand-primary-10);
  padding: 12px 2rem;
}
.registerForm-footer {
  width: 100%;
  padding: 1rem 0 1rem 0;
}

.fieldErrorAlert {
  flex-basis: 100%;
  background-color: var(--amplify-components-alert-error-background-color);
  padding: 0 2em;
  border-radius: 4px;
}

@media screen and (min-width: 767px) {
  .authenticator-container {
    width: 40rem !important;
  }

  .textFieldsWrapper {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  .textFields {
    width: 48%;
  }

  .checkbox-label {
    flex-wrap: nowrap;
  }
}
