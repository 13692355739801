div.amplify-alert {
  flex-basis: 100%;
}

div[data-amplify-sign-up-errors="true"] {
  display: none;
}

.input-controlled.amplify-input,
.input-controlled.amplify-select {
  border-color: var(--amplify-components-fieldcontrol-border-color);
}

.input-controlled.amplify-input:focus,
.input-controlled.amplify-select:focus {
  border-color: var(--amplify-components-fieldcontrol-focus-border-color);
  box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
}

.inputFieldError.amplify-input,
.inputFieldError.amplify-select {
  border-color: var(--amplify-components-fieldcontrol-error-border-color);
}

.inputFieldError.amplify-input:focus,
.inputFieldError.amplify-select:focus {
  border-color: var(--amplify-components-fieldcontrol-error-border-color);
  box-shadow: var(--amplify-components-fieldcontrol-error-border-color);
}

#radix-2-content-0:focus-visible {
  outline: none;
}

.FormSubmitButton-container {
  width: 100%;
}

.footer-container {
  justify-content: space-between;
  align-items: center;
  flex-direction: column-reverse;
}

.amplify-tabs {
  display: none;
}

fieldset > button[type="submit"] {
  display: none;
}
fieldset ~ button[type="submit"] {
  display: none;
}

[data-amplify-authenticator] [data-amplify-container] {
  background-color: var(--amplify-components-authenticator-router-background-color);
  box-shadow: var(--amplify-components-authenticator-router-box-shadow);
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: none;
  box-shadow: none;
  border-color: none;
  border-width: none;
  border-style: none;
  background-color: transparent;
}

[data-amplify-authenticator] {
  height: 100%;
  margin: auto;
}

label.amplify-checkbox {
  align-items: flex-start;
}

@media screen and (min-width: 480px) {
  label.amplify-checkbox {
    align-items: center;
  }
}

@media screen and (min-width: 767px) {
  .FormSubmitButton-container {
    width: 40%;
    max-width: 192px;
  }
  .footer-container {
    flex-direction: row;
  }
  [data-amplify-authenticator] [data-amplify-container] {
    border-radius: 8px;
  }
  [data-amplify-authenticator] {
    padding: 1em 0;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  [data-amplify-authenticator] {
    margin: 2rem 0;
  }
}
